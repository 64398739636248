import { useTranslation } from "react-i18next"
import { useStore } from "@nanostores/react"
import { useFirstMountState } from "@react-hookz/web"

import { $cart } from "@stores/cart"

import { ReactComponent as IconShoppingBag } from "@icons/bag.svg"

const CartButton = (): JSX.Element => {
  const { t } = useTranslation<string>()
  const { recipe_count } = useStore($cart)
  const isFirstMount = useFirstMountState()

  return (
    <div className="relative">
      <div className="flex">
        <div className="w-24">
          <IconShoppingBag />
          <span className="sr-only">{t("common:navigation.cart")}</span>
        </div>
        {!isFirstMount && recipe_count > 0 && (
          <div className="absolute -top-4 -right-8 h-16 min-w-16 bg-spinach flex rounded-16 px-4 pointer-events-none">
            <div className="font-semibold text-10 flex m-auto text-white">{recipe_count}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CartButton
