import { useState, useRef, useEffect } from "react"
import cc from "classnames"
import { useTranslation } from "react-i18next"
import { motion, AnimatePresence, useScroll, useAnimation } from "framer-motion"
import useSWR from "swr"

import { $searchDialog } from "@stores/searchDialog"
import fetcher from "@utils/fetcher"
import computeSlug from "@utils/computeSlug"
import type { HeaderPropertiesDef, HoverStatus, LayoutDef } from "@types"

import Container from "@components/Container"
import CollectionWithRefresh from "@components/CollectionWithRefresh"
import Link from "@components/Link"
import HeaderIcons from "./HeaderIcons"
import PreNav from "./PreNav"

import { ReactComponent as HappyPlatesLogo } from "src/assets/happy-plates-logo.svg"
import { ReactComponent as ChevronDownSmall } from "@icons/chevron-down-small.svg"
import { ReactComponent as Smile } from "@icons/smile.svg"

interface HeaderDesktopProps {
  layout: LayoutDef
  header_properties: HeaderPropertiesDef
  url: URL
}

const HeaderDesktop = (props: HeaderDesktopProps): JSX.Element => {
  const { layout, header_properties, url } = props
  const asPath = `${url.pathname}${url.search}`

  const { bg_color = "white" } = header_properties

  const { t } = useTranslation<string>()
  const { scrollY } = useScroll()
  const controls = useAnimation()

  const variants = {
    open: {
      height: 550,
      transition: {
        when: "beforeChildren",
        duration: 0.15,
        delay: 0.15,
        ease: "easeOut",
      },
    },
    closed: { height: 0, transition: { when: "afterChildren", duration: 0.15, ease: "easeOut" } },
  }

  const item = {
    open: { y: 0, opacity: 1, transition: { ease: "easeOut", duration: 0.1 } },
    closed: { y: -30, opacity: 0, transition: { duration: 0.05, ease: "easeOut" } },
  }

  const headerBg = {
    open: { height: "100%", transition: { duration: 0.15, ease: "easeOut" } },
    closed: { height: "0%", transition: { duration: 0.2, ease: "easeOut", delay: 0.15 } },
  }

  const [reducedHeader, setReducedHeader] = useState(false)
  const [currentBgColor, setCurrentBgColor] = useState(bg_color)
  const [hoverStatus, setHoverStatus] = useState<HoverStatus>("none")
  const [hoverNav, setHoverNav] = useState<boolean>(false)

  // TODO this is reused on SearchEmptyResults, we should consider adding these values to globals
  const headerCollection = useSWR("/v1/random/inspiration/2621", fetcher, { revalidateOnFocus: false })?.data
  const scrollYValue = useRef(0)
  const reducedHeaderActive = useRef(false)
  const headerRef = useRef<HTMLDivElement>(null)
  const anchorRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const svgRef = useRef<SVGSVGElement>(null)

  useEffect(() => {
    const unsubscribeScrollListener = scrollY.on("change", (latest) => {
      // if we are scrolling down and the header reduced header wasn't triggered yet
      if (latest > 200 && scrollYValue.current < latest && !reducedHeaderActive.current) {
        controls.start("open")
        reducedHeaderActive.current = true
        setReducedHeader(true)
        setCurrentBgColor("white")
      }

      if (latest < 400 && scrollYValue.current > latest && reducedHeaderActive.current) {
        controls.start("closed")
        reducedHeaderActive.current = false
        setCurrentBgColor(bg_color)
      }
      scrollYValue.current = latest
    })
    return () => {
      unsubscribeScrollListener()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!svgRef || !svgRef.current) return

    if (hoverStatus === "recipes") {
      if (!anchorRef || !anchorRef.current || !menuRef || !menuRef.current) return

      const anchor = anchorRef.current.getBoundingClientRect()
      const menu = menuRef.current.getBoundingClientRect()

      const path = svgRef.current.querySelector("path")
      if (path) {
        path.style.pointerEvents = "all"
        path.setAttribute(
          "d",
          `M${anchor.x + anchor.width / 2} ${anchor.y + anchor.height / 2} L${menu.x + menu.width} ${menu.y}H${
            menu.y
          } 0Z`
        )
      }
    } else {
      const path = svgRef.current.querySelector("path")
      if (path) path.style.pointerEvents = "none"
    }
  }, [hoverStatus])

  const handleSearchClick = () => $searchDialog.setKey("is_open", true)

  return (
    <div className={cc("w-full", { relative: bg_color === "white", absolute: bg_color === "transparent" })}>
      <div
        className={cc("h-auto relative left-0 top-0 w-full z-20 transition-colors duration-200", {
          "text-black": currentBgColor === "white",
          "text-white": currentBgColor === "transparent",
        })}
      >
        <PreNav layout={layout} bg_color={bg_color} />
        {/* This Fake Navigation is a non functional copy of the header nav and gets shown, when the reduced header is active.
        This prevents an empty header nav when scrolling back up  */}
        <div
          aria-hidden
          className={cc("fake-nav", {
            "h-104": reducedHeader,
            "h-0 opacity-0 pointer-events-none": !reducedHeader,
            "border-b border-b-neutral-200": reducedHeader && bg_color === "white",
            "text-white": reducedHeader && bg_color === "transparent",
          })}
        >
          <Container>
            <div className="grid grid-cols-12 border-b border-transparent h-104">
              <div className="col-span-5 flex h-full">
                <div className="flex flex-col text-18 mr-24 group">
                  <div className="flex items-center h-full z-10 relative">
                    {t("common:common.recipes")}
                    <div className="h-12 w-12 ml-4 my-auto flex">
                      <div className="h-12 w-12 my-auto">
                        <ChevronDownSmall />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col text-18 mr-24 group">
                  <div className="flex items-center h-full z-10 relative">{t("common:navigation.bundles")}</div>
                </div>
                <div className="flex flex-col text-18 mr-24 group">
                  <div className="flex items-center h-full z-10 relative">{t("common:navigation.blog")}</div>
                </div>
              </div>
              <div className="col-span-2 flex items-center justify-center relative z-10">
                <Link href="/" style="none" class_name="w-76 block mx-auto">
                  <HappyPlatesLogo />
                  <span className="sr-only">{t("common:common.frontpage")}</span>
                </Link>
              </div>
              <HeaderIcons url={url} onSearchClick={handleSearchClick} />
            </div>
          </Container>
        </div>
        <motion.div
          className={cc("relative w-full", {
            "h-104 !top-0": !reducedHeader,
            "h-56 !fixed left-0 -top-104 bg-white text-black": reducedHeader,
          })}
          animate={controls}
          onAnimationComplete={() => {
            if (!reducedHeaderActive.current) {
              setReducedHeader(false)
            }
          }}
          variants={{
            open: { top: [-104, 0], transition: { ease: "easeOut", duration: 0.3 } },
            closed: { top: -104, transition: { ease: "easeOut", duration: 0.3 } },
          }}
          onMouseOver={() => {
            if (!hoverNav) {
              setCurrentBgColor("white")
              setHoverNav(true)
            }
          }}
          onMouseEnter={() => {
            setCurrentBgColor("white")
            setHoverNav(true)
          }}
          onMouseLeave={() => {
            setCurrentBgColor(bg_color)
            setHoverNav(false)
          }}
          data-cy="header-desktop"
        >
          <motion.div
            className={cc("absolute h-px w-full left-0 -mt-px bg-transparent z-10", {
              "top-104": !reducedHeader,
              "top-56": reducedHeader,
            })}
            initial="closed"
            variants={{
              open: { backgroundColor: "#E8E8E8", transition: { delay: 0.2 } },
              closed: {
                backgroundColor: currentBgColor === "white" ? "#E8E8E8" : "rgba(255, 255, 255, 0)",
                transition: { duration: 0 },
              },
            }}
            animate={hoverNav ? "open" : "closed"}
          />
          <motion.div
            className="bg-white w-full absolute left-0 top-0 z-0"
            variants={headerBg}
            animate={hoverNav ? "open" : "closed"}
          ></motion.div>
          <Container>
            <nav
              className={cc("grid grid-cols-12 border-b border-transparent", {
                "h-104": !reducedHeader,
                "h-56": reducedHeader,
              })}
            >
              <div className="col-span-5 flex h-full">
                <div
                  className="nav-link flex flex-col text-18 mr-24"
                  ref={anchorRef}
                  onMouseEnter={() => setHoverStatus("recipes")}
                  onMouseLeave={() => setHoverStatus("none")}
                >
                  <Link class_name="flex items-center h-full relative z-20 " href="/r">
                    {t("common:common.recipes")}
                    <div className="h-12 w-12 ml-4 my-auto flex">
                      <div className="h-12 w-12 my-auto">
                        <ChevronDownSmall />
                      </div>
                    </div>
                    <div
                      className={cc(
                        "nav-link-line absolute left-0 bottom-0 w-0 -mb-px h-px transition-all duration-150 ease-out force-translate bg-black",
                        {
                          "!w-full":
                            bg_color === "white" &&
                            (asPath === "/r" ||
                              asPath?.startsWith("/r/") ||
                              asPath === "/c" ||
                              asPath?.startsWith("/c/")) &&
                            hoverNav === false,
                        }
                      )}
                    />
                    <svg ref={svgRef} className="nav-svg fixed left-0 top-0 z-20" key="svg">
                      <path fill="transparent"></path>
                    </svg>
                  </Link>
                  <AnimatePresence>
                    <motion.div
                      animate={hoverStatus === "recipes" ? "open" : "closed"}
                      initial="hidden"
                      variants={variants}
                      ref={menuRef}
                      className={cc(
                        "absolute w-full left-0 h-0 bg-white z-0 drop-shadow-light drop-shadow-b rounded-b-[2px]",
                        {
                          "absolute top-104": !reducedHeader,
                          "fixed top-56": reducedHeader,
                          "!pointer-events-none": hoverStatus !== "recipes",
                        }
                      )}
                    >
                      <Container>
                        <div className="w-full pt-48 pb-64 grid grid-cols-24 gap-x-16">
                          {layout.collection_groups.slice(0, 2).map((group, i) => {
                            return (
                              <motion.div
                                variants={item}
                                key={i}
                                className="flex flex-col justify-between items-start col-span-6"
                              >
                                <div>
                                  <p className="text-18 font-semibold mb-24">{group.collection_title}</p>
                                  <ul className="list-none">
                                    {group.collections.slice(0, 11).map((collection, i) => {
                                      return (
                                        <li
                                          key={collection.id}
                                          className={cc("font-light", {
                                            "leading-[120%] mb-12 ": i !== 10,
                                          })}
                                        >
                                          <Link href={`/c/${collection.id}/${computeSlug(collection.name, true)}`}>
                                            <span className="link-hover-underline">{collection.name}</span>
                                          </Link>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </div>
                                <Link
                                  class_name="bg-spinach hover:bg-spinach-hover font-semibold text-white text-14 p-12 rounded-4"
                                  href={i === 0 ? "/r" : "/c"}
                                >
                                  {i === 0 ? t("common:navigation.allRecipes") : t("common:navigation.allCollections")}
                                </Link>
                              </motion.div>
                            )
                          })}
                          <motion.div variants={item} className="flex col-start-14 col-span-11">
                            <CollectionWithRefresh
                              collection={headerCollection?.collection}
                              recipes={headerCollection?.recipes}
                              recipes_shown={3}
                            />
                          </motion.div>
                        </div>
                      </Container>
                    </motion.div>
                  </AnimatePresence>
                </div>
                <div className="nav-link flex flex-col text-18 mr-24">
                  <Link class_name="flex items-center h-full z-10 relative" href="/b">
                    {t("common:navigation.bundles")}
                    <div
                      className={cc(
                        "nav-link-line absolute left-0 bottom-0 w-0 -mb-px h-px transition-all duration-150 ease-out force-translate bg-black",
                        {
                          "!w-full":
                            bg_color === "white" &&
                            (asPath === "/b" || asPath?.startsWith("/b/")) &&
                            hoverNav === false,
                        }
                      )}
                    />
                  </Link>
                </div>
                <div className="nav-link flex flex-col text-18 mr-24">
                  <Link class_name="flex items-center h-full relative z-10" href="/blog">
                    {t("common:navigation.blog")}
                    <div
                      className={cc(
                        "nav-link-line absolute left-0 bottom-0 w-0 -mb-px h-px transition-all duration-150 ease-out force-translate bg-black",
                        {
                          "!w-full": bg_color === "white" && asPath.split("?")[0] === "/blog" && hoverNav === false,
                        }
                      )}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-span-2 flex items-center justify-center relative z-10">
                <Link href="/" style="none" class_name="w-76 block mx-auto">
                  {reducedHeader ? (
                    <div className="p-16">
                      <Smile />
                    </div>
                  ) : (
                    <HappyPlatesLogo />
                  )}
                  <span className="sr-only">{t("common:common.frontpage")}</span>
                </Link>
              </div>
              <HeaderIcons url={url} onSearchClick={handleSearchClick} />
            </nav>
          </Container>
        </motion.div>
      </div>
      <div id="header-bottom" ref={headerRef} />
    </div>
  )
}

export default HeaderDesktop
