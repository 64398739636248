import type { MouseEvent } from "react"
import { useTranslation } from "react-i18next"
import { useStore } from "@nanostores/react"

import { openCreateAccountDialog } from "@stores/createAccountDialog"
import { open } from "@stores/cart"
import { $auth } from "@stores/auth"
import { analyticsTrackEvent } from "@utils/analytics"

import SearchInput from "@components/SearchInput"
import Link from "@components/Link"
import CartButton from "@components/CartButton"
import { ReactComponent as IconBookmarkNavigation } from "@icons/bookmark-navigation.svg"

const HeaderIcons = (props: { url: URL; onSearchClick: () => void }): JSX.Element => {
  const { t } = useTranslation<string>()
  const { user } = useStore($auth)

  const handleFavoriteClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (!user) {
      openCreateAccountDialog({
        redirect_after_login: "/favorites",
      })
      e.preventDefault()
      analyticsTrackEvent("registration", { action: "modal_opened", label: "header" })
    }
  }

  const onCartClick = () => {
    open()
    analyticsTrackEvent("cart", { action: "view_cart", label: "header" })
  }

  return (
    <div className="col-span-5 flex items-center relative z-10">
      <div className="flex relative mr-40 ml-auto z-10">
        <SearchInput
          label={t("common:header.search")}
          value={(props.url.searchParams.get("query") as string) || ""}
          data-cy="desktop-faux-search-input"
          onFocus={(e) => {
            // Trick to make focus not return to this element when the search
            // dialog is clicked, which would lead to undesirable situations
            // (dialog would immediately open again, making it unclosable)
            e.currentTarget.blur()
            props.onSearchClick()
          }}
        />
      </div>
      <Link href="/favorites" title={t("common:navigation.favorites") ?? ""} onClick={handleFavoriteClick}>
        <div className="p-8">
          <div className="w-24 mr-8">
            <IconBookmarkNavigation />
            <span className="sr-only">{t("common:navigation.favorites")}</span>
          </div>
        </div>
      </Link>
      <button onClick={onCartClick} className="p-2" data-cy="header-cart-button">
        <CartButton />
      </button>
    </div>
  )
}

export default HeaderIcons
