import { forwardRef, type ChangeEvent, type MouseEvent, type FocusEvent } from "react"
import cc from "classnames"

import { ReactComponent as MagnifyingGlass } from "@icons/magnifying-glass.svg"
import { ReactComponent as CloseSmall } from "@icons/close-small.svg"

interface TextInputProps {
  onChange?: (string: string) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  value: string
  label: string
  placeholder?: string
  id?: string
  icon_right?: boolean
  auto_focus?: boolean
  label_class_name?: string
  large?: boolean
  read_only?: boolean
  "aria-invalid"?: boolean
  "aria-describedby"?: string
  "data-cy"?: string
}

const Input = (props: TextInputProps, ref: any): JSX.Element => {
  const inputProps = {
    placeholder: props.placeholder || props.label,
    value: props.value,
    onChange: (e: ChangeEvent<HTMLInputElement>) => props.onChange?.(e.target.value),
    onFocus: props.onFocus,
    className: cc(
      !props.large && "placeholder-neutral-300 h-40 block w-full",
      props.large && "h-64 border border-neutral-200 placeholder-neutral-300 p-24 block w-full rounded-16",
      props.icon_right ? "pr-40" : "pl-40"
    ),
    name: props.id,
    ref,
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    window.location.href = window.location.pathname
  }

  return (
    <label className="block w-full flex-1">
      <div className="flex justify-between items-center relative">
        <div className="pr-8 sr-only absolute text-neutral-300 top-0 left-0 w-full h-full px-24 py-20 pointer-events-none">
          {props.label}
        </div>
      </div>
      <div className={cc(props.large ? "" : "bg-neutral-100 rounded-full")}>
        <input {...inputProps} type="text" data-cy={props["data-cy"]} readOnly={props.read_only} />
        {props.value && (
          <button className="absolute right-12 top-1/2 -mt-8" onClick={handleClick}>
            <CloseSmall className="h-16 w-16 text-neutral-300" />
          </button>
        )}
      </div>
      <button
        data-cy={`${props["data-cy"]}-submit`}
        type="submit"
        className={cc(
          "absolute",
          props.icon_right ? "text-neutral-300 top-24 transform right-20 w-16" : "top-10 left-10 text-black w-20"
        )}
      >
        <MagnifyingGlass />
      </button>
    </label>
  )
}

const SearchInput = forwardRef(Input)

export default SearchInput
